.footer {
    padding: 40px 20px;
    flex-grow: 0;
    color: $light-color-secondary;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 760px;
        max-width: 100%;

        @media #{$media-size-tablet} {
            flex-direction: column;
        }
    }

    &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 1rem;
        color: $light-color-secondary;

        @media #{$media-size-tablet} {
            flex-direction: column;
            margin-top: 10px;
        }

        & > *:not(:last-child)::after {
            content: "•";
            padding: 0 5px;

            @media #{$media-size-tablet} {
                content: "";
                padding: 0;
            }
        }

        & > *:last-child {
            //   padding: 0 5px;

            @media #{$media-size-tablet} {
                padding: 0;
            }
        }
    }
}
