.really-wide-monitor {
    display: none;
}

@media #{$media-size-widescreen} {
    .really-wide-monitor {
        background: none;
        display: block;
        font-size: 3rem;
        padding: 12px;
        position: fixed;
        right: 0px;
        top: 3rem;
        transform: rotate(90deg) translate(100%, 100%);
        transform-origin: 100% 100%;
    }
}
